import React from 'react'

import Layout from '../../components/Layout'
import SignInForm, {
  SignInGoogle,
  SignInFacebook,
  SignInTwitter,
} from '../../components/SignIn'
import { SignUpLink } from '../../components/SignUp'
import { ForgotPasswordLink } from '../../components/ForgotPassword'

const SignInPage = () => (
  <div className="columns">
    <div className="column is-10 is-offset-1">
      <div className="section">
        <h1 className="has-text-white">Sign In</h1>
        <div className="card">
          <div className="card-content">
            <section className="section">
              <SignInForm />
              <SignInGoogle />
              <SignInFacebook />
              <SignInTwitter />
              <ForgotPasswordLink />
              <SignUpLink />
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const SignIn = () => {
  return (
    <Layout>
      <section className="section section-gradient">
        <div className="container">
          <div className="content">
            <SignInPage />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SignIn
