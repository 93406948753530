import React, { Component } from 'react'

import { Link, navigate } from 'gatsby'
import ReCAPTCHA from 'react-google-recaptcha'

import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  mobileNumber: '',
  passwordOne: '',
  passwordTwo: '',
  message: '',
  error: null,
  verified: false,
}

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use'

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`

class SignUpFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  sendEmail = async email => {
    const options = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        email,
        subject: 'Verify your email for 99 Sense',
      }),
    }

    await fetch('/.netlify/functions/verify', options)
      .then(response => response.json())
      .then(json => this.setState({ message: json }))
      .catch(error => this.setState({ error }))
  }

  onSubmit = event => {
    const { firstName, lastName, email, mobileNumber, passwordOne } = this.state

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        return this.props.firebase.createUser({
          userId: authUser.user.uid,
          firstName,
          lastName,
          mobileNumber,
        })
      })
      .then(() => {
        this.sendEmail(email)
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE })
        navigate(ROUTES.THANK_YOU, {
          state: {
            heading: 'Thank you for signing up!',
            title: 'Thank you!',
            subtitle:
              'A message has been sent. Please verify your email from your inbox to confirm user registration.',
            button: 'Login',
          },
        })
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }

        this.setState({ error })
      })

    event.preventDefault()
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onChangeCheckbox = event => {
    this.setState({ [event.target.name]: event.target.checked })
  }

  onVerify = () => {
    this.setState({ verified: true })
  }

  onExpired = () => {
    this.setState({ verified: false })
  }

  render() {
    const {
      firstName,
      lastName,
      mobileNumber,
      email,
      passwordOne,
      passwordTwo,
      error,
      verified,
    } = this.state

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      firstName === '' ||
      lastName === '' ||
      verified === false

    return (
      <form onSubmit={this.onSubmit}>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label" htmlFor={'firstName'}>
                First Name
              </label>
              <div className="control">
                <input
                  className="input"
                  name="firstName"
                  value={firstName}
                  onChange={this.onChange}
                  type="text"
                  placeholder="First Name"
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor={'email'}>
                Email Address
              </label>
              <div className="control">
                <input
                  className="input"
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  type="email"
                  placeholder="Email Address"
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor={'passwordOne'}>
                Password
              </label>
              <div className="control">
                <input
                  className="input"
                  name="passwordOne"
                  value={passwordOne}
                  onChange={this.onChange}
                  type="password"
                  placeholder="Password"
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label" htmlFor={'lastName'}>
                Last Name
              </label>
              <div className="control">
                <input
                  className="input"
                  name="lastName"
                  value={lastName}
                  onChange={this.onChange}
                  type="text"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor={'mobileNumber'}>
                Mobile Number
              </label>
              <div className="control">
                <input
                  className="input"
                  name="mobileNumber"
                  value={mobileNumber}
                  onChange={this.onChange}
                  type="text"
                  placeholder="Mobile Number"
                />
              </div>
            </div>
            <div className="field">
              <label className="label" htmlFor={'passwordTwo'}>
                Confirm Password
              </label>
              <div className="control">
                <input
                  className="input"
                  name="passwordTwo"
                  value={passwordTwo}
                  onChange={this.onChange}
                  type="password"
                  placeholder="Confirm Password"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="field has-text-centered">
          <ReCAPTCHA
            className="g-recaptcha"
            sitekey={process.env.GATSBY_RECAPTCHA_V2_SITE_KEY}
            onChange={this.onVerify}
            onExpired={this.onExpired}
          />
        </div>
        <div className="field has-text-centered pt-5">
          <button
            className="button is-link is-rounded"
            disabled={isInvalid}
            type="submit"
          >
            Sign Up
          </button>
        </div>
        {error && <p>{error.message}</p>}
      </form>
    )
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
)

export default withFirebase(SignUpFormBase)

export { SignUpLink }
