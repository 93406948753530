import React, { Component } from 'react'
import { navigate } from 'gatsby'
import ReCAPTCHA from 'react-google-recaptcha'

import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'
import GoogleSignInButton from '../GoogleSignInButton'

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  verified: false,
}

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential'

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`

class SignInFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  onSubmit = event => {
    const { email, password } = this.state

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
        navigate(ROUTES.JOIN_NETWORK)
      })
      .catch(error => {
        this.setState({ error })
      })

    event.preventDefault()
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onVerify = () => {
    this.setState({ verified: true })
  }

  render() {
    const { email, password, error, verified } = this.state

    const isInvalid = password === '' || email === '' || verified === false

    return (
      <form onSubmit={this.onSubmit}>
        <div className="field">
          <label className="label" htmlFor={'email'}>
            Email Address
          </label>
          <div className="control">
            <input
              className="input"
              name="email"
              value={email}
              onChange={this.onChange}
              type="email"
              placeholder="Email Address"
            />
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor={'password'}>
            Password
          </label>
          <div className="control">
            <input
              className="input"
              name="password"
              value={password}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
            />
          </div>
        </div>
        <div className="field">
          <ReCAPTCHA
            sitekey={process.env.GATSBY_RECAPTCHA_V2_SITE_KEY}
            onChange={this.onVerify}
          />
        </div>
        <div className="field">
          <button
            className="button is-link is-rounded"
            disabled={isInvalid}
            type="submit"
          >
            Sign In
          </button>
        </div>

        {error && <p>{error.message}</p>}
      </form>
    )
  }
}

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props)

    this.state = { error: null }
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(socialAuthUser => {
        return this.props.firebase.createUser(
          socialAuthUser.user.uid,
          socialAuthUser.user.displayName,
          socialAuthUser.user.email
        )
      })
      .then(() => {
        this.setState({ error: null })
        navigate(ROUTES.HOME)
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }

        this.setState({ error })
      })

    event.preventDefault()
  }

  render() {
    const { error } = this.state

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Google</button>

        <GoogleSignInButton />

        {error && <p>{error.message}</p>}
      </form>
    )
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props)

    this.state = { error: null }
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(socialAuthUser => {
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        })
      })
      .then(() => {
        this.setState({ error: null })
        navigate(ROUTES.HOME)
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }

        this.setState({ error })
      })

    event.preventDefault()
  }

  render() {
    const { error } = this.state

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Facebook</button>

        {error && <p>{error.message}</p>}
      </form>
    )
  }
}

class SignInTwitterBase extends Component {
  constructor(props) {
    super(props)

    this.state = { error: null }
  }

  onSubmit = event => {
    this.props.firebase
      .doSignInWithTwitter()
      .then(socialAuthUser => {
        return this.props.firebase.user(socialAuthUser.user.uid).set({
          username: socialAuthUser.additionalUserInfo.profile.name,
          email: socialAuthUser.additionalUserInfo.profile.email,
          roles: {},
        })
      })
      .then(() => {
        this.setState({ error: null })
        navigate(ROUTES.HOME)
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }

        this.setState({ error })
      })

    event.preventDefault()
  }

  render() {
    const { error } = this.state

    return (
      <form onSubmit={this.onSubmit}>
        <button type="submit">Sign In with Twitter</button>

        {error && <p>{error.message}</p>}
      </form>
    )
  }
}

const SignInForm = withFirebase(SignInFormBase)

const SignInGoogle = withFirebase(SignInGoogleBase)

const SignInFacebook = withFirebase(SignInFacebookBase)

const SignInTwitter = withFirebase(SignInTwitterBase)

export default SignInForm

export { SignInGoogle, SignInFacebook, SignInTwitter }
