import React, { Component } from 'react'
import { Link } from 'gatsby'

import { withFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

const INITIAL_STATE = {
  email: '',
  message: '',
  error: null,
}

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  sendEmail = async email => {
    const options = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        email,
        subject: 'Reset your password for 99 Sense',
      }),
    }

    await fetch('/.netlify/functions/reset', options)
      .then(response => response.json())
      .then(json => this.setState({ message: json }))
      .catch(error => this.setState({ error }))
  }

  onSubmit = event => {
    const { email } = this.state

    this.sendEmail(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE })
      })
      .catch(error => {
        this.setState({ error })
      })

    event.preventDefault()
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { email, error } = this.state

    const isInvalid = email === ''

    return (
      <form onSubmit={this.onSubmit}>
        <div className="field">
          <label className="label" htmlFor={'email'}>
            Email Address
          </label>
          <div className="control">
            <input
              className="input"
              name="email"
              value={email}
              onChange={this.onChange}
              type="email"
              placeholder="Email Address"
            />
          </div>
        </div>
        <div className="field">
          <button className="button is-link" disabled={isInvalid} type="submit">
            Reset My Password
          </button>
        </div>

        {error && <p>{error.message}</p>}
      </form>
    )
  }
}

const ForgotPasswordLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
)

export default withFirebase(ForgotPasswordForm)

export { ForgotPasswordLink }
