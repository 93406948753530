import React from 'react'

import './button.css'

const GoogleSignInButton = () => {
  return (
    <div className="g-sign-in-button">
      <div className="content-wrapper">
        <div className="logo-wrapper">
          <img
            src="https://developers.google.com/identity/images/g-logo.png"
            alt="Google"
          />
        </div>
        <span className="text-container">
          <span>Sign in with Google</span>
        </span>
      </div>
    </div>
  )
}

export default GoogleSignInButton
